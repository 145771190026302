// React Basic and Bootstrap
import React, { Component } from "react";
import { Link } from "react-router-dom";
import {
  Container,
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  Row,
  Col,
} from "reactstrap";
import classnames from "classnames";

//Import Components
import SectionTitle from "../../components/Shared/SectionTitle";

//import images
// import app1 from "../../assets/images/app/1.png";
// import app2 from "../../assets/images/app/2.png";
// import app3 from "../../assets/images/app/3.png";
// import app4 from "../../assets/images/app/4.png";
import app2 from "../../assets/images/app/zenstrin/8.png";
import app3 from "../../assets/images/app/zenstrin/6.png";
import app4 from "../../assets/images/property-management/ai-agents/fully-automated.png";

class ShowCase extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeTab: "1",
    };
    this.toggle = this.toggle.bind(this);
  }

  toggle(tab) {
    this.setState({ activeTab: tab });
  }

  render() {
    return (
      <React.Fragment>
        <section className="section pt-0 ">
          {/* <Container>
            <SectionTitle
              title="How Can We Help You ?"
              desc="that can provide everything you need to generate awareness, drive traffic, connect."
            />

            <Row className="align-items-center">
              <Col lg="5" md="6" className="mt-4 pt-2">
                <img src={app1} className="img-fluid mx-auto d-block" alt="" />
              </Col>

              <Col lg={7} md={6} className="mt-4 pt-2">
                <div className="section-title ms-lg-5">
                  <h4 className="title mb-4">Best <span className="text-primary">Landrick</span> App Partner For Your Life</h4>
                  <p className="text-muted">You can combine all the Landrick templates into a single one, you can take a component from the Application theme and use it in the Website.</p>
                  <ul className="list-unstyled text-muted">
                    <li className="mb-1"><span className="text-primary h5 me-2"><i className="uil uil-check-circle align-middle"></i></span>Digital Marketing Solutions for Tomorrow</li>
                    <li className="mb-1"><span className="text-primary h5 me-2"><i className="uil uil-check-circle align-middle"></i></span>Our Talented & Experienced Marketing Agency</li>
                    <li className="mb-1"><span className="text-primary h5 me-2"><i className="uil uil-check-circle align-middle"></i></span>Create your own skin to match your brand</li>
                  </ul>
                  <Link to="#" className="mt-3 h6 text-primary">Find Out More {" "}
                    <i className="uil uil-angle-right-b"></i></Link>
                </div>
              </Col>
            </Row>
          </Container> */}
          {/* <Container className="mt-100 mt-60"> */}
          <Container className="">
            <Row className="justify-content-center">
              <Col xs="12" className="text-center">
                <div className="section-title mb-4 pb-2">
                  <h4 className="title mb-4">
                    <span className="text" style={{ color: "#f7961c" }}>AI</span> Agents
                  </h4>
                  <p className="text-muted para-desc mb-0 mx-auto">
                    Save time by leveraging{" "}
                    <span className="text fw-bold" style={{ color: "#f7961c" }}>
                      Zenstrin's AI
                    </span>{" "}
                    agents to automate your business processes.
                  </p>
                </div>
              </Col>
            </Row>

            <Row className="justify-content-center">
              <Col lg={8} md={12} className="mt-4 pt-2 text-center">
                <Nav
                  pills
                  justified
                  id="pills-tab"
                  className="flex-column flex-sm-row rounded"
                >
                  {/* <NavItem>
                    <NavLink
                      className={classnames(
                        { active: this.state.activeTab === "1" },
                        "rounded"
                      )}
                      onClick={() => {
                        this.toggle("1");
                      }}
                    >
                      <div className="text-center py-2">
                        <h6 className="mb-0">
                          AI Search
                        </h6>
                      </div>
                    </NavLink>
                  </NavItem> */}
                  <NavItem>
                    <NavLink
                      className={classnames(
                        { active: this.state.activeTab === "1" },
                        "rounded"
                      )}
                      onClick={() => {
                        this.toggle("1");
                      }}
                    >
                      <div className="text-center py-2">
                        <h6 className="mb-0">
                          Tenant Assistant
                        </h6>
                      </div>
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className={classnames(
                        { active: this.state.activeTab === "2" },
                        "rounded"
                      )}
                      onClick={() => {
                        this.toggle("2");
                      }}
                    >
                      <div className="text-center py-2">
                        <h6 className="mb-0">
                          Complaince Agent
                        </h6>
                      </div>
                    </NavLink>
                  </NavItem>
                </Nav>
              </Col>
            </Row>

            <Row>
              <Col xs="12" className="mt-4 pt-2">
                <TabContent activeTab={this.state.activeTab}>
                  {/* <TabPane tabId="1">
                    <Row className="align-items-center">

                      <Col md={12} className="mt-4 mt-sm-0 pt-2 pt-sm-0">
                        <div className="section-title">
                          <h4 className="title mb-4"><i className="uil uil-angle-double-right text" style={{ color: "#f7961c" }}></i> {" "} AI Search</h4>
                          <p className="text-muted"></p>
                          <ul className="list-unstyled text-muted">
                            <li className="mb-1"><span className="text h5 me-2" style={{ color: "#f7961c" }}><i className="uil uil-check-circle align-middle"></i></span>
                              Allow renters/buyers to find you faster
                            </li>
                            <li className="mb-1"><span className="text h5 me-2" style={{ color: "#f7961c" }}><i className="uil uil-check-circle align-middle"></i></span>
                              Get leads sent directly to you
                            </li>
                          </ul>
                          <Link to="/ai-search" className="mt-3 h6 text" style={{ color: "#f7961c" }}>Explore AI Search {" "}
                            <i className="uil uil-angle-right-b"></i></Link>
                        </div>
                      </Col>
                    </Row>
                  </TabPane> */}
                  <TabPane tabId="1">
                    <Row className="align-items-center">

                      <Col md={12} className="mt-4 mt-sm-0 pt-2 pt-sm-0">
                        <div className="section-title">
                          <h4 className="title mb-4"><i className="uil uil-angle-double-right text" style={{ color: "#f7961c" }}></i> {" "} Tenant Assistant</h4>
                          <p className="text-muted"></p>
                          <ul className="list-unstyled text-muted">
                            <li className="mb-1"><span className="text h5 me-2" style={{ color: "#f7961c" }}><i className="uil uil-check-circle align-middle"></i></span>
                              Respond to tenant queries 24/7
                            </li>
                            <li className="mb-1"><span className="text h5 me-2" style={{ color: "#f7961c" }}><i className="uil uil-check-circle align-middle"></i></span>
                              Save time responding to tenant queries
                            </li>
                          </ul>
                          <Link to="/features/ai" className="mt-3 h6 text" style={{ color: "#f7961c" }}>Explore Agents {" "}
                            <i className="uil uil-angle-right-b"></i></Link>
                        </div>
                      </Col>
                    </Row>
                  </TabPane>
                  <TabPane tabId="2">
                    <Row className="align-items-center">
                      {/* <Col md={6}>
                        <img
                          src={app3}
                          className="img-fluid mx-auto d-block"
                          alt=""
                        />
                      </Col> */}

                      <Col md={12} className="mt-4 mt-sm-0 pt-2 pt-sm-0">
                        <div className="section-title">
                          <h4 className="title mb-4"><i className="uil uil-angle-double-right text" style={{ color: "#f7961c" }}></i> Compliance Agent</h4>
                          <p className="text-muted"></p>
                          <ul className="list-unstyled text-muted">
                            <li className="mb-1"><span className="text h5 me-2" style={{ color: "#f7961c" }}><i className="uil uil-check-circle align-middle"></i></span>Avoid costly fines and penalties
                            </li>
                            <li className="mb-1"><span className="text h5 me-2" style={{ color: "#f7961c" }}><i className="uil uil-check-circle align-middle"></i></span>Ensure compliance with local, national regulations and your company policies
                            </li>
                            <li className="mb-1"><span className="text h5 me-2" style={{ color: "#f7961c" }}><i className="uil uil-check-circle align-middle"></i></span>We scan all your documents and flag any potential issues.
                            </li>
                          </ul>
                          <Link to="/features/ai" className="mt-3 h6 text" style={{ color: "#f7961c" }}>Explore Agents {" "}
                            <i className="uil uil-angle-right-b"></i></Link>
                        </div>
                      </Col>
                    </Row>
                  </TabPane>
                  <TabPane tabId="3">
                    <Row className="align-items-center">
                      {/* <Col md={6}>
                        <img
                          src={app4}
                          className="img-fluid mx-auto d-block"
                          alt=""
                          style={{borderRadius: 20}}
                        />
                      </Col> */}

                      <Col md={12} className="mt-4 mt-sm-0 pt-2 pt-sm-0">
                        <div className="section-title">
                          <h4 className="title mb-4"><i className="uil uil-angle-double-right text" style={{ color: "#f7961c" }}></i> Fully automated</h4>
                          <p className="text-muted"></p>
                          <ul className="list-unstyled text-muted">
                            <li className="mb-1"><span className="text h5 me-2" style={{ color: "#f7961c" }}><i className="uil uil-check-circle align-middle"></i></span>An autonomous AI property manager that works 24/7
                            </li>
                            <li className="mb-1"><span className="text h5 me-2" style={{ color: "#f7961c" }}><i className="uil uil-check-circle align-middle"></i></span>Outsource your property management tasks to AI agents
                            </li>
                            <li className="mb-1"><span className="text h5 me-2" style={{ color: "#f7961c" }}><i className="uil uil-check-circle align-middle"></i></span>Automate day to day tasks like sending rental applications, answering tenant queries, scheduling maintenance requests, and more
                            </li>
                          </ul>
                          <Link to="/features/ai" className="mt-3 h6 text" style={{ color: "#f7961c" }}>Explore Agents {" "}
                            <i className="uil uil-angle-right-b"></i></Link>
                        </div>
                      </Col>
                    </Row>
                  </TabPane>
                </TabContent>
              </Col>
            </Row>
          </Container>
        </section>
      </React.Fragment>
    );
  }
}

export default ShowCase;
